
import Vue from 'vue'
import { returnChange, uploadFile, uploadChunk, createChunks, getNetwork } from '@/api.ts'
import LoginLogout from '@/components/LoginLogout.vue'
import { config } from '@/config'

export default Vue.extend({
  name: 'HomeView',
  data() {
    return {
      fileAddress: false,
      file: false,
      max: 100, //progress bar max value
      value: 0, //progress bar value
      showProgress: false, //show progress bar
      disableInputs: false, //disable inputs
      showInput: true,
      btnLoader: false, //show loader on button
      authTrigger: false,
      showAlert: false,
      alertContent: '',
    }
  },
  methods: {
    loadFile(ev) {
      this.file = ev.target.files[0]
      this._uploadFile()
    },
    async _uploadFile() {
      if (this.file == false) {
        alert('Please select a file')
        return
      }
      this.btnLoader = true
      this.disableInputs = true
      const fileAddress = await uploadFile(this.file)

      if (typeof fileAddress === 'undefined') {
        this.btnLoader = false
        this.disableInputs = false
        return
      }

      let base64: string
      try {
        base64 = await readUploadedFileAsBase64(this.file)
      } catch (e) {
        console.warn(e.message)
      }

      const chunks = createChunks(base64, 50000)// max size is up to 64kb
      this.btnLoader = false
      this.showProgress = true

      this.max = chunks.length - 1 //progress bar

      let promises = []
      for (let i = 0; i < chunks.length; i++) {
        promises.push(uploadChunk(fileAddress, chunks[i], i))
        if ((i + 1) % 50 == 0) {
          await Promise.all(promises)
          promises = []
        }
        this.value = i
      }

      await Promise.all(promises)

      //return change and destroy file contract (don't need it anymore)
      returnChange(fileAddress)

      this.showProgress = false
      this.disableInputs = false
      document.getElementById('fileUpload').value = ''
      this.fileAddress = fileAddress
    },
    controlNetwork() {
      if (this.$network !== config.network) {
        this.showAlert = true
        this.alertContent = 'This project is currently working in ' + config.network + '. Switch your EverWallet to this network.'
        this.showInput = false
      } else {
        this.showAlert = false
        this.showInput = true
      }
    },
  },
  components: {
    LoginLogout,
  },
  watch: {
    $network() {
      this.controlNetwork()
    },
  },
  mounted() {
    getNetwork().then((network) => {
      this.$network = network
      this.controlNetwork()
    })
  },
})

const readUploadedFileAsBase64 = (inputFile) => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }
    temporaryFileReader.readAsDataURL(inputFile)
  })
}
